import React, { useState } from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import { useStaticQuery, graphql } from "gatsby"
import GalleryItem from "../components/gallery-item"
import Grid from "../components/grid"
import Narrow from "../components/narrow"
import { Helmet } from "react-helmet"
import Button from "../components/button"

const StyledPicturesPage = styled.div`
  text-align: center;
  padding-bottom: 3rem;
  h1 {
    margin: 3rem 0 0 0;
    text-transform: uppercase;
    font-weight: 300;
  }
  .myndir-page-text {
    max-width: 800px;
    margin: auto;
  }
  .myndir-page-contact-button {
    margin-top: 2rem;
  }
`

const PicturesPage = () => {
  const [active, setActive] = useState(null)
  const query = useStaticQuery(graphql`
    query {
      contentfulGeneral {
        myndirPageText {
          childMarkdownRemark {
            html
          }
        }
      }
      contentfulMyndir {
        galleryImages {
          id
          lowRes {
            fluid {
              ...GatsbyContentfulFluid
            }
          }
          fullRes {
            fluid {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
  `)

  const toggleActive = index => {
    if (index !== active) {
      setActive(index)
    } else {
      setActive(null)
    }
  }

  return (
    <Layout>
      <Helmet>
        <title>Agnes Suto | Myndir</title>
      </Helmet>
      <StyledPicturesPage>
        <h1>Myndir</h1>
        <Narrow>
          <div
            className="myndir-page-text"
            dangerouslySetInnerHTML={{
              __html:
                query.contentfulGeneral.myndirPageText.childMarkdownRemark.html,
            }}
          ></div>
          <div className="myndir-page-contact-button">
            <a href="mailto:agnessuto.is@gmail.com">
              <Button>HAFA SAMBAND</Button>
            </a>
          </div>
          <Grid>
            {query.contentfulMyndir.galleryImages.map((image, index) => (
              <GalleryItem
                image={image}
                active={active === index}
                alt=""
                key={image.id}
                onClick={() => {
                  toggleActive(index)
                }}
              />
            ))}
          </Grid>
        </Narrow>
      </StyledPicturesPage>
    </Layout>
  )
}

export default PicturesPage
